import { render, staticRenderFns } from "./renewal-table.vue?vue&type=template&id=0b184506&scoped=true"
import script from "./renewal-table.vue?vue&type=script&lang=js"
export * from "./renewal-table.vue?vue&type=script&lang=js"
import style0 from "./renewal-table.vue?vue&type=style&index=0&id=0b184506&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b184506",
  null
  
)

export default component.exports