<template>
  <div class="edit">
    <el-dialog
      title="续期审核"
      :visible.sync="isShow"
      width="40%"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        class="form"
        hide-required-asterisk
      >
        <el-form-item label="审核结果" prop="pass">
          <el-select v-model="form.pass" placeholder="选择">
            <el-option
              v-for="item in examOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        pass: ""
      },
      examOpts: [
        { value: 0, label: "拒绝" },
        { value: 1, label: "通过" }
      ]
    };
  },
  computed: {
    // title() {
    //   let { username = '', mobile = '' } = this.editData;
    //   return username ? '编辑用户 -' + username : '编辑用户 - ' + mobile;
    // }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.$refs["form"].resetFields();
    },
    submit() {
      // console.log(this.form.amount,this.form.riskPass, this.form)
      let form = {
        pass: this.form.pass
      };
      if (this.form.pass === "") {
        this.$message.error("请选择审核结果");
        return false;
      }
      this.$emit("submit", form);
      this.$refs["form"].resetFields();
    },
    openDialog() {
      this.$nextTick(() => {
        // this.form.exam = this.editData.status;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit {
  .form {
    width: 70%;
    margin: 0 auto;
    text-align: left;
    .el-select {
      width: 100%;
    }
  }
}
</style>
