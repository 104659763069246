import API from "@/api";

export default {
  table: params => {
    return API.requestGet({
      url: "/loan/repayment/renewal",
      params
    });
  },
  setRenew: params => {
    return API.requestPost({
      url: "/loan/repayment/auditRenew",
      data: params
    });
  },
};
